body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* { 
	box-sizing: border-box;
}

html, body {
	min-height: 100%;
	background: #fff;
	color: #000;
}
	/* sets the body height of the browser, so that backgrounds and div heights work correctly. Color and background will almost certainly be altered; they are just placeholders */
	
body {
	font-size: 1.4rem;
	text-rendering: optimizeLegibility;
}
	/* sets the base font size for the document; turns on kerning pairs and ligatures */
	
body, ul, ol, dl {
	margin: 0;
}
article, aside, audio, 
footer, header, nav, section, video {
	display: block; 
	}
	
	/* ensures that older browsers will display HTML5 elements correctly */
	
h1 {
	font-size: 2rem;
}
	/* begins to set up font sizes relative to base rem – h1 has been used as an example */
	
p { 
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
} 
	/* sets hyphenation by default on paragraphs */
	
textarea { 
	resize: vertical;
}
 /* changes textarea resizing from "both" (UA default) to vertical only */
 
table { border-collapse: collapse; }
td {
	padding: .5rem;
}
	/* causes tables to actually look like tables */
	
img { 
	border: none;
	max-width: 100%;
}
	/* removes outlines from linked images (for older browsers and IE), sets up images for responsive design layout */
	
input[type="submit"]::-moz-focus-inner, 
	input[type="button"]::-moz-focus-inner {
		border : 0px;
	}
	/* removes the inner border effect from focused buttons for form elements in Firefox */
	
input[type="search"] { 
	-webkit-appearance: textfield;
} 
input[type="submit"] { 
	-webkit-appearance:none;
}
	/* removes the OS X appearance from HTML5 search inputs and submit buttons when viewed in Safari */
	
input:required:after {
	color: #f00;
	content: " *";
}
	/* sets up required form fields with the conventional following red asterix */
input[type="email"]:invalid { 
	background: #f00;
}
	/* causes a visual alert in browsers that do client-side checking of HTML5 email field – this may already be the default in some browsers. */
	
.right { 
	float: right;
	margin-left: 2rem;
	clear: right;
}
.left { 
	float: left;
	margin-right: 2rem;
	clear: left;
}
	/* allows a quick shortcut for floating elements left and right while avoiding the “float quirk” problem */
	
sub, sup { 
	line-height: 0;
}
    /* sets superscript and subscript text so that it doesn't throw off leading for elements in which they are included */